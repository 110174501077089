import React from 'react';
import { FdIconsV5 } from '@fifthdomain/fe-shared';
import TrophyRoomIcon, { SkillsIcon } from './customIcons';

const {
  DashboardRounded,
  ArticleRounded,
  Loyalty,
  HomeRounded,
  Ballot,
  People,
  Link: LinkIcon,
  LinkOff,
  Spoke,
  AccountBalanceRounded,
  SupervisedUserCircleRounded,
} = FdIconsV5;

const MenuItems = {
  adminHome: {
    name: 'Home',
    path: '/landing',
    icon: <HomeRounded />,
  },
  participantHome: {
    name: 'Home',
    path: '/landing/landing-homepage',
    icon: <HomeRounded />,
  },
  mySkills: {
    name: 'My Skills',
    path: '/competitions/skills-overview',
    icon: <SkillsIcon />,
  },
  myTeams: {
    name: 'My Teams',
    path: '/landing/teams',
    icon: <SupervisedUserCircleRounded />,
  },
  content: {
    name: 'Content',
    path: '/labs',
    icon: <ArticleRounded />,
  },
  hallOfFame: {
    name: 'Hall of Fame',
    path: '/landing/hall-of-fame',
    icon: <AccountBalanceRounded />,
  },
  users: {
    name: 'Users',
    path: '/labs',
    icon: <People />,
  },
  templateLibrary: {
    name: 'Template Library',
    path: '/org/template-library',
    icon: <Ballot />,
  },
  tagDirectory: {
    name: 'Tag Directory',
    path: '/user-management/tags',
    icon: <Loyalty />,
  },
  dashboards: {
    name: 'Dashboards',
    path: '/assessor/dashboards',
    icon: <DashboardRounded />,
  },
  trophyRoom: {
    name: 'Trophy Room',
    path: '/competitions/trophy-room',
    icon: <TrophyRoomIcon />,
  },
  // children
  labs: {
    name: 'Labs',
    path: '/labs',
  },
  vms: {
    name: 'VMs',
    path: '/labs/vms',
  },
  quizzes: {
    name: 'Quizzes',
    path: '/labs/quizzes',
  },
  challenges: {
    name: 'Challenges',
    path: '/tasks',
  },
  affiliated: {
    name: 'Affiliated',
    path: '/user-management/affiliated-users',
    icon: <LinkIcon />,
  },
  nonAffiliated: {
    name: 'Non-Affiliated',
    path: '/user-management/non-affiliated-users',
    icon: <LinkOff />,
  },
  squads: {
    name: 'Squad(s)',
    path: '/user-management/squads',
    icon: <Spoke />,
  },
};

export default MenuItems;
